<template>
  <div>
    

    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >

    
    
      <b-carousel-slide
        v-for="(item) in carousel.media" :key="item._id"
        @click="goto(item._id)"
        :img-src="root_url+item.thumbnail"
      >
      
      </b-carousel-slide>

    </b-carousel>

  </div>
</template>

<script>

import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        root_url: process.env.VUE_APP_DOMAIN_API + 'images/',
        slide: 0,
        sliding: null
      }
    },
    methods: {
      goto (link) {
        console.log(link);
      },
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    },
    async mounted () {

    },
    props: {
      carousel: {
        type: Object
      }
    }
  }
</script>