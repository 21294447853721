<template>
  <nav class="navbar-header">
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top" :class="blur ? 'addBlur': 'deleteBlur'">
      <b-container fluid="">
        <b-navbar-brand to="/">
        <img width="150" src="../../assets/images/logo/logo2.png" alt="" style="margin-top: -10px"></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->


          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="goTo('')" class="ml-3">Home</b-nav-item>

            <!-- <b-nav-item-dropdown text="Radio" right>
              <b-dropdown-item @click="goTo('radio')">รายการสด</b-dropdown-item>
              <b-dropdown-item @click="goTo('radio/history')">ฟังย้อนหลัง</b-dropdown-item>
            </b-nav-item-dropdown> -->
                 <b-nav-item @click="goTo('radio/history')" class="ml-3">ฟังย้อนหลัง</b-nav-item>

            <b-nav-item @click="goTo('podcast')" class="ml-3">Podcast</b-nav-item>

            <b-nav-item @click="goTo('think_studio')" class="ml-3">Think studio</b-nav-item>

            <b-nav-item @click="goTo('hot_issue')" class="ml-3">Hot issue</b-nav-item>

            <b-nav-item @click="goTo('shopping')" class="ml-3">Shopping</b-nav-item>

            <!-- <b-nav-item-dropdown text="Think studio" right>
              <b-dropdown-item @click="goToTS(item.id, item.name)" v-for="item in categories.think_studio" :key="item.id">{{ item.name }}</b-dropdown-item>
            </b-nav-item-dropdown> -->




            <!-- <b-nav-item @click="goTo('shopping')" class="ml-3">Shopping</b-nav-item> -->
            <b-nav-item @click="goTo('partner')" class="ml-3">Partner</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>


  </nav>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'


export default {
  data() {
    return {
      blur: true,
      lastClick: null
    }
  },
  watch: {
    '$route': {
      handler: function() {
        this.track()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('website', ['getCategoryPage', 'getBlogGroup']),
    ...mapMutations('website', ['setBlogHead']),
    track () {

      this.$gtag.pageview(this.$route)
    },
    gotoPodcast (link) {
      this.$router.push(link)
    },
    async filterLink ( link, name ) {

      await this.getCategoryPage({ type: 'podcast', cate: link, name: name });
      this.$router.push(`/podcast/${link}`);
    },
    async goTo( type ) {

      this.$router.push( '/'+type );
      scroll(0, 0);
    },

    async goToTS ( cate, name ) {

      this.$router.push( '/think_studio/'+cate );

      await this.setBlogHead( name )
      await this.getBlogGroup({
        type: 'think_studio',
        category: cate
      });
    },

    async goToIS ( cate, name ) {

      this.$router.push( '/hot_issue/'+cate );

      await this.setBlogHead( name )
      await this.getBlogGroup({
        type: 'hot_issue',
        category: cate
      });
    },

  },
  async mounted () {

},

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('website', ['category', 'categories'])
  }

}
</script>


<style scoped>
.addBlur {
  background: #ffffffdb !important;
  transition: 1s;
}
.deleteBlur {
  background: #fff !important;
  transition: 1s;
}
</style>