<template>
  <footer class="bg-footer text-white ">
    <b-container class="py-5">
      <b-row>
        <b-col md="3" class="text-center">
          <img width="150" src="../../assets/images/logo/logopure.png" alt="">
          <p>Follow Us</p>
          <social-sharing
            url="https://vuejs.org/"
            title="The Progressive JavaScript Framework"
            description="Intuitive, Fast and Composable MVVM for building interactive interfaces."
            quote="Vue is a progressive framework for building user interfaces."
            hashtags="vuejs,javascript,framework"
            twitter-user="vuejs"
            inline-template
          >
            <div>
              <a href="https://www.facebook.com/Thinkingradio/" target="_blank" class="linkk">
                <i class="fab fa-facebook white-color fa-2x mr-2" />
              </a>
              <a href="https://lin.ee/OG4pQE" target="_blank" class="linkk">
                <i class=" fab fa-line white-color fa-2x mr-2" />
              </a>
              <a href="https://twitter.com/thinkingradioFM" target="_blank" class="linkk">
                <i class="fab fa-twitter white-color fa-2x mr-2" />
              </a>
              
              <a href="https://www.instagram.com/thinkingradio/" target="_blank" class="linkk">
                <!-- <i class="fab fa-instragram white-color fa-2x mr-2" /> -->
                <i class="fab fa-instagram fa-2x mr-2" ></i>
              </a>
            </div>
          </social-sharing>
        </b-col>
     
        <b-col md="4" class="pt-3">
          <h5 class="h--footer">Web Contact</h5>
          <ul class="pl-0" style="list-style: none">
            <li class="pt-3"><span class="link-page">Admin : <a href="mailto:thk.channels@gmail.com">thk.channels@gmail.com</a></span></li>
            <li class="pt-3"><span class="link-page">Tel :  <a href="tel:022488260">02 2488260</a> ( Mon – Fri : 9.00 -17.00 )</span></li>
            
            <li class="pt-3"><span class="link-page">Mobile : <a href="tel:0814940442" >08149440442</a> </span></li>
          </ul>
        </b-col>
        <b-col md="5" class="pt-3">
          
          <h5 class="h--footer">Contact Us</h5>
          <ul class="pl-0" style="list-style: none">
            <li class="pt-3"><span class="link-page">คลื่นความคิด 96.5 F.M. ขั้น 4 อาคารปฏิบัติการ </span></li>
            <li class="pt-3"><span class="link-page">บริษัท อสมท จำกัด (มหาชน)</span></li>
            
            <li class="pt-3"><span class="link-page">63/1 ถ.พระราม 9 ห้วยขวาง กรุงเทพฯ 10310</span></li>
          </ul>
          

        </b-col>

      </b-row>
    </b-container>
    <div class="footer-bottom">
      <b-container>
        <span>Copyright © 2019 Company Name. All rights reserved.</span>
      </b-container>
    </div>
  </footer>
</template>

<script>
import socialSharing from 'vue-social-sharing'
export default {
  components: {
    socialSharing
  }
}
</script>

<style>
  footer .h1 {
    color: #fff;
    font-size: 1.26rem;
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .link-page {
    color: #bfbfbf;
  }
  .link-page:hover {
    color: #fff;
  }
  .footer-bottom{
    background: #000;
    padding: 5px;
    width: 100%
  }
  .footer-bottom span {
    color: #808080;
  }
  .bg-footer{
    background: rgb(51, 51, 51);
  }
  .h--footer {
    color: #f29726;
  }
  .linkk {
    color: #a7a7a7 !important;
  }
  a{
  color: inherit;
  text-decoration: none;
}
</style>
